import React from "react";

function MyPic() {

    return (
        <div>
            <img
                src="https://d3t14gfu9ehll4.cloudfront.net/themes/c7e7ae88-9de6-40a2-89e9-f78879f9b03b/root_SmallGraphic.png"
                alt="image"
                style={{
                    width: '80px',
                    height: '80px',
                }}
            />
        </div>
    )
}

export default MyPic;